import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Search, Grid, List, ArrowUpDown, Eye, Heart, DollarSign, Wand2, Image } from 'lucide-react';

const ActiveProducts = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [viewMode, setViewMode] = useState('grid');
  const [searchQuery, setSearchQuery] = useState('');
  const [sortField, setSortField] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc');
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState(null);
  const [selectedSection, setSelectedSection] = useState('all');
  const [error, setError] = useState(null);
  const [updatingProduct, setUpdatingProduct] = useState(null);

  useEffect(() => {
    fetchProducts(currentPage);
  }, [currentPage]);

  const fetchProducts = async (page) => {
    try {
      setLoading(true);
      setError(null);
      const token = localStorage.getItem('token');
      const response = await axios.get(`https://etsyhelper.onrender.com/api/active-products?page=${page}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setProducts(response.data.products);
      setPagination(response.data.pagination);
    } catch (error) {
      console.error('Error fetching products:', error);
      setError('Failed to fetch products. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleMagicUpdate = async (productId) => {
    try {
      setUpdatingProduct(productId);
      const token = localStorage.getItem('token');
      await axios.post(`https://etsyhelper.onrender.com/api/update-title/${productId}`, {}, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      await fetchProducts(currentPage);
      setUpdatingProduct(null);
    } catch (error) {
      console.error('Error updating title:', error);
      setUpdatingProduct(null);
    }
  };

  const handleMockupUpdate = async (productId) => {
    try {
      setUpdatingProduct(productId);
      const token = localStorage.getItem('token');
      await axios.post(`https://etsyhelper.onrender.com/api/update-mockup/${productId}`, {}, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      await fetchProducts(currentPage);
      setUpdatingProduct(null);
    } catch (error) {
      console.error('Error updating mockup:', error);
      setUpdatingProduct(null);
    }
  };

  const sections = ['all', ...new Set(products.map(p => p.section_title))];

  const filteredProducts = products
    .filter(product => {
      const matchesSearch = product.name.toLowerCase().includes(searchQuery.toLowerCase());
      const matchesSection = selectedSection === 'all' || product.section_title === selectedSection;
      return matchesSearch && matchesSection;
    })
    .sort((a, b) => {
      const modifier = sortOrder === 'asc' ? 1 : -1;
      switch (sortField) {
        case 'price':
          return (parseFloat(a[sortField]) - parseFloat(b[sortField])) * modifier;
        case 'views':
        case 'num_favorers':
          return (a[sortField] - b[sortField]) * modifier;
        default:
          return String(a[sortField]).localeCompare(String(b[sortField])) * modifier;
      }
    });

  const toggleSort = (field) => {
    if (sortField === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="w-16 h-16 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 p-6">
      <div className="max-w-7xl mx-auto">
        <div className="mb-8">
          <h1 className="text-3xl font-bold text-gray-900">Active Products</h1>
          <p className="text-gray-600 mt-2">Manage your active Etsy listings</p>
        </div>

        <div className="bg-white rounded-lg shadow p-6 mb-6">
          <div className="flex flex-col md:flex-row md:items-center gap-4 mb-6">
            <div className="flex-1 relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
              <input
                type="text"
                placeholder="Search products..."
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>

            <div className="flex items-center gap-4">
              <select
                className="px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                value={sortField}
                onChange={(e) => setSortField(e.target.value)}
              >
                <option value="name">Sort by Name</option>
                <option value="price">Sort by Price</option>
                <option value="views">Sort by Views</option>
                <option value="num_favorers">Sort by Favorites</option>
              </select>

              <select
                className="px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                value={selectedSection}
                onChange={(e) => setSelectedSection(e.target.value)}
              >
                {sections.map(section => (
                  <option key={section} value={section}>
                    {String(section) === 'all' ? 'All Categories' : String(section)}
                  </option>
                ))}
              </select>

              <div className="flex gap-2 bg-gray-100 p-1 rounded-lg">
                <button
                  onClick={() => setViewMode('grid')}
                  className={`p-2 rounded ${viewMode === 'grid' ? 'bg-white shadow' : ''}`}
                >
                  <Grid size={20} />
                </button>
                <button
                  onClick={() => setViewMode('list')}
                  className={`p-2 rounded ${viewMode === 'list' ? 'bg-white shadow' : ''}`}
                >
                  <List size={20} />
                </button>
              </div>
            </div>
          </div>

          {viewMode === 'grid' ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {filteredProducts.map((product) => (
                <div key={product.id} className="bg-white border border-gray-200 rounded-lg overflow-hidden hover:shadow-lg transition-shadow">
                  <div className="aspect-square relative overflow-hidden">
                    <img
                      src={product.base_image || '/placeholder-image.jpg'}
                      alt={product.name}
                      className="object-cover w-full h-full hover:scale-110 transition-transform duration-300"
                    />
                    <div className="absolute top-2 right-2 bg-white px-2 py-1 rounded-full shadow text-sm">
                      {product.currency} {product.price}
                    </div>
                  </div>
                  <div className="p-4">
                    <div className="min-h-[3rem]">
                      <a 
                        href={product.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-base font-semibold text-gray-900 hover:text-blue-500 line-clamp-2"
                      >
                        {product.name}
                      </a>
                    </div>
                    
                    <div className="flex items-center justify-between gap-4 text-sm text-gray-600 my-3">
                      <div className="flex items-center gap-1">
                        <Eye size={16} />
                        {product.views}
                      </div>
                      <div className="flex items-center gap-1">
                        <Heart size={16} />
                        {product.num_favorers}
                      </div>
                      <div className="flex items-center gap-1">
                        <DollarSign size={16} />
                        {product.quantity}
                      </div>
                    </div>

                    <div className="flex justify-between gap-2 mt-4">
                      <button
                        onClick={() => handleMagicUpdate(product.id)}
                        disabled={updatingProduct === product.id}
                        className="flex items-center justify-center gap-2 px-3 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 disabled:opacity-50 flex-1"
                      >
                        <Wand2 size={16} />
                        {updatingProduct === product.id ? 'Updating...' : 'Magic Title'}
                      </button>
                      <button
                        onClick={() => handleMockupUpdate(product.id)}
                        disabled={updatingProduct === product.id}
                        className="flex items-center justify-center gap-2 px-3 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 disabled:opacity-50 flex-1"
                      >
                        <Image size={16} />
                        {updatingProduct === product.id ? 'Updating...' : 'Update Mockup'}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-sm font-semibold text-gray-900">Product</th>
                    <th className="px-6 py-3 text-left text-sm font-semibold text-gray-900">Price</th>
                    <th className="px-6 py-3 text-left text-sm font-semibold text-gray-900">Views</th>
                    <th className="px-6 py-3 text-left text-sm font-semibold text-gray-900">Favorites</th>
                    <th className="px-6 py-3 text-left text-sm font-semibold text-gray-900">Actions</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {filteredProducts.map((product) => (
                    <tr key={product.id} className="hover:bg-gray-50">
                      <td className="px-6 py-4">
                        <div className="flex items-center gap-4">
                          <img
                            src={product.base_image || '/placeholder-image.jpg'}
                            alt={product.name}
                            className="w-16 h-16 rounded-lg object-cover"
                          />
                          <a 
                            href={product.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="font-medium text-gray-900 hover:text-blue-500"
                          >
                            {product.name}
                          </a>
                        </div>
                      </td>
                      <td className="px-6 py-4 text-gray-600">
                        {product.currency} {product.price}
                      </td>
                      <td className="px-6 py-4 text-gray-600">{product.views}</td>
                      <td className="px-6 py-4 text-gray-600">{product.num_favorers}</td>
                      <td className="px-6 py-4">
                        <div className="flex gap-2">
                          <button
                            onClick={() => handleMagicUpdate(product.id)}
                            disabled={updatingProduct === product.id}
                            className="flex items-center gap-1 px-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:opacity-50"
                          >
                            <Wand2 size={14} />
                            Magic
                          </button>
                          <button
                            onClick={() => handleMockupUpdate(product.id)}
                            disabled={updatingProduct === product.id}
                            className="flex items-center gap-1 px-2 py-1 bg-green-500 text-white rounded hover:bg-green-600 disabled:opacity-50"
                          >
                            <Image size={14} />
                            Mockup
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* Pagination */}
          {pagination && (
            <div className="mt-6 flex justify-between items-center">
              <div className="text-sm text-gray-500">
                Showing {products.length} of {pagination.total_items} items
              </div>
              <div className="flex gap-2">
                {pagination.has_prev && (
                  <button
                    onClick={() => setCurrentPage(currentPage - 1)}
                    className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200"
                  >
                    Previous
                  </button>
                )}
                
                {Array.from({ length: Math.min(5, pagination.total_pages) }, (_, i) => {
                  let pageNum;
                  if (pagination.total_pages <= 5) {
                    pageNum = i + 1;
                  } else if (currentPage <= 3) {
                    pageNum = i + 1;
                  } else if (currentPage >= pagination.total_pages - 2) {
                    pageNum = pagination.total_pages - (4 - i);
                  } else {
                    pageNum = currentPage - 2 + i;
                  }

                  return (
                    <button
                      key={i}
                      onClick={() => setCurrentPage(pageNum)}
                      className={`px-4 py-2 rounded-lg ${
                        currentPage === pageNum
                          ? 'bg-blue-500 text-white'
                          : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                      }`}
                    >
                      {pageNum}
                    </button>
                  );
                })}

                {pagination.has_next && (
                  <button
                    onClick={() => setCurrentPage(currentPage + 1)}
                    className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200"
                  >
                    Next
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ActiveProducts;